<template>
  <PaymentTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
    :events="state.event.events"
    :payments="state.currentUser.paymentMethods"
    @onDone="onDone"
    :price="1500"
  />
</template>

<script>
import PaymentTemplate from "@/components/templates/Payment/Payment";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";
import { Settings } from "../../settings";

export default {
  components: {
    PaymentTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "Payment",
      },
      product: null,
      isAvailable: false,
      currentReservation: null,
    };
  },
  async created() {
    try {
      // const res = await this.actions.user.getReservations({where: {id: this.$route.query?.rId}});
      // const currentReservation = res[0];
      // this.currentReservation = currentReservation
      // const reservations = await this.actions.user.getReservations({
      //   where: {
      //     status: 'HOLD',
      //     reservationDate_gte: currentReservation?.event?.days[0]?.startDate,
      //     reservationDate_lte: currentReservation?.event?.days[0]?.endDate,
      //   }
      // });
      // console.log(reservations, 'reservations')
      // this.isAvailable = reservations?.filter(r => r?.id !== this.$route.query?.rId).length > 0 ? false : true;
      // console.log(this.isAvailable, 'isAvailable')
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async onDone(paymentMethod) {
      // if (!this.isAvailable) {
      //   this.actions.alert.showError({message: 'There is another holding reservation in your time range. Please try it again later.'});
      //   return false;
      // }
      console.log(paymentMethod);
      let cart = null;
      if (this.$route.query?.cId) {
        const carts = await this.actions.cart.getCarts({
          where: { id: this.$route.query?.cId },
          getValues: true,
        });
        cart = carts[0];
      } else {
        const saveCartParams = {
          findMarketplace: false,
          addItems: [
            {
              quantity: 1,
              productId: Settings.productId,
              originalProductId: Settings.productId,
              deliverBy: new Date(),
              deliverTo: { id: Settings.siteId },
            },
          ],
        };
        const saveCart = await this.actions.cart.saveCart(saveCartParams);
        console.log(saveCart, "saveCart");
        cart = saveCart;
      }

      const orderParams = {
        cartId: cart.id,
        tipType: "$",
        tipAmount: cart.tip,
        paymentMethodId: paymentMethod?.id,
      };
      const order = await this.actions.order.createOrder(orderParams);
      await this.actions.user.saveReservation({
        where: { id: this.$route.query?.rId },
        data: {
          order: { connect: { id: order?.id } },
          status: "PENDING",
        },
      });
      await this.actions.event.saveEvent({
        where: { id: this.currentReservation?.event?.id },
        data: {
          isActive: true,
        },
      });
      this.$router.push({ name: "Booking Success", query: { oId: order?.id } });
    },
  },
};
</script>

<style scoped></style>
