<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<!-- <h2 class="entry-title">{{content.title}}</h2> -->
			<div class="entry-content">
				<PaymentForm :price="price" :payment-form="paymentForm" @onFinalize="onFinalize" :payment-methods="paymentMethods" :payments="payments" />
                <!-- <Button type="submit" @click="submit" class="button-filled button-color submit-btn" size="size" variant="abc">Complete Booking</Button> -->
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
import Text from '../../atoms/Text/Text.vue'
import PaymentForm from '../../organisms/PaymentForm/PaymentForm.vue';
import {isEmpty} from 'lodash'
import { Settings } from '../../../../settings';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, Carousel, CopyRight, Text, PaymentForm
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        },
        payments: {
            type: Array,
            default: () => []
        },
        price: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            footerProps,
            moment: moment,
            paymentForm: {
                cardNumber: null,
                expirationDate: null,
                zipCode: null,
                cvv: null,
            }
        }
    },
    computed: {
        paymentMethods() {
            const items = [];
            this.payments?.map(p => items.push({
                id: p?.id,
                title: `Saved Payment Method ****${p?.last4}`
            }))
            items.push({    
                id: null,
                title: 'Add New Payment Method'
            })
            return {
                label: 'Saved CC Payment Methods',
                items
            }
        }
    },
    methods: {
        validate: function () {
            try {
                let message = null;
                const mnow = moment();
                const items = this.paymentForm.expirationDate?.split('/')
                const curYear = parseInt(mnow.format('YYYY'), 10);
                const curMonth = parseInt(mnow.format('MM'), 10);
                const month = parseInt(items[0], 10);
                const year = parseInt(items[1], 10);

                // if (isEmpty(this.state.currentUser.firstName) && isEmpty(this.state.currentUser.lastName)) {
                //   this.showError({message: 'Please enter user name'});
                //   return;
                // }
                if (isEmpty(this.paymentForm.cardNumber)) {
                    message = 'Please enter valid card number'
                }
                if (year < curYear || (year === curYear && month < curMonth)) {
                    message = 'Please enter valid expiration date';
                }
                if (isEmpty(this.paymentForm.cvv)) {
                    message = 'Please enter cvv';
                }
                if (isEmpty(this.paymentForm.zipCode)) {
                    message = 'Please enter zip code';
                }
                if (message) {
                    this.actions.alert.showError({ message });
                    return false;
                }
                return true;
            } catch(e) {
                console.log(e)
            }
            
        },
        async onFinalize(selectedPayment) {
            if (!selectedPayment?.id) {
                if (this.payments?.find(p => p?.last4 === selectedPayment?.cardNumber?.substr(selectedPayment?.cardNumber?.length - 4))) {
                    this.actions.alert.showError({message: 'There is already same card number. Please try another number.'});
                    return false;
                }
                if (this.validate()) {
                    const items = this.paymentForm.expirationDate?.split('/')
                    const month = parseInt(items[0], 10);
                    const year = parseInt(items[1], 10);
                    const params = {
                        ...this.paymentForm,
                        month,
                        year,
                    }
                    const user = await this.addPaymentMethod(params);
                    if (user) {
                        this.$emit('onDone', user.paymentMethods?.[user?.paymentMethods?.length - 1])
                    }
                }
            } else {
                this.$emit('onDone', selectedPayment)
            }
        },
        addPaymentMethod: async function (pMethod) {
            try {
                let paymentMethodResponse = null;
                if (Settings.merchantId) {
                    const generateBraintreeClientToken = await this.actions.user.generateBraintreeClientToken({ customerId: this.$route.query?.uId });
                    console.log(generateBraintreeClientToken, 'generateBraintreeClientToken')
                    const clientInstance = await createClient({ authorization: generateBraintreeClientToken });

                    // Warning: For a merchant to be eligible for the easiest level of PCI compliance (SAQ A),
                    // payment fields cannot be hosted on your checkout page.
                    // For an alternative to the following, use Hosted Fields.
                    console.log({ expirationDate: ('0' + pMethod.month.toString()).slice(-2) + '/' + pMethod.year.toString() })
                    paymentMethodResponse = await clientInstance.request({
                        endpoint: 'payment_methods/credit_cards',
                        method: 'post',
                        data: {
                        creditCard: {
                            number: pMethod.cardNumber,
                            cvv: pMethod.cvv,
                            expirationDate: ('0' + pMethod.month.toString()).slice(-2) + '/' + pMethod.year.toString(),
                            billingAddress: {
                            postalCode: pMethod.zipCode
                            },
                            options: {
                            validate: false
                            }
                        }
                        }
                    });

                    // console.log(paymentMethodResponse, 'paymentMethodResponse');

                }

                const user = await this.actions.user.updateUserProfile({
                paymentMethod: {
                    firstName: this.state.currentUser.firstName,
                    lastName: this.state.currentUser.lastName,
                    verificationCode: pMethod.cvv,
                    month: ('0' + pMethod.month.toString()).slice(-2),
                    year: pMethod.year.toString(),
                    number: pMethod.cardNumber,
                    type: 'payment',
                    nonce: paymentMethodResponse ? paymentMethodResponse.creditCards[0].nonce : null,
                    billingZip: pMethod.zipCode
                }
                });

                if (user) {
                    this.actions.alert.showSuccess({ message: 'Credit card added successfully.' });
                    return user;
                } else {
                    this.actions.alert.showError({ message: 'Failed to add credit card' });
                }
            } catch (ex) {
                console.log('AddCard::_requestAdd failed: ', ex);
                this.actions.alert.showError({ message: 'Failed to add credit card' });

            } finally {
                
            }
        }
    }
}
</script>

<style>

</style>